.container {
    padding-inline: 15px;
}

.section {
    padding-block: var(--section-padding);
}

.h1, .h2 {
    letter-spacing: -2px;
}

.h1 {
    color: var(--light-gray);
    font-size: var(--fs-1);
    line-height: 1.1;
}

.h2, .h3 {
    color: var(--slate-gray);
}

.h2 {
    font-size: var(--fs-2);
    line-height: 1.2;
}

.h3 {
    font-size: var(--fs-3);
    line-height: 1.3;
    font-weight: var(--fw-500);
}

.has-before,
.has-after {
    position: relative;
    z-index: 1;
}

.has-before::before,
.has-after::after {
    content: "";
    position: absolute;
}

:is(.h1, .h2) .has-before {
    display: inline-block;
}

:is(.h1, .h2) .has-before::before {
    bottom: 8px;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: var(--majorelle-blue);
    z-index: -1;
}

.btn {
    max-width: max-content;
    font-weight: var(--fw-700);
    height: 55px;
    display: flex;
    align-items: center;
    padding-inline: 50px;
    border-radius: var(--radius-10);
    overflow: hidden;
    transition: var(--transition-2);
}

.btn::before, .btn::after {
    right: 0;
    width: 100%;
    height: 50%;
    transform: scaleX(0);
    background-color: var(--majorelle-blue);
    z-index: -1;
    transition: transform var(--transition-1);
}

.btn::before {
    top: 0;
    transform-origin: left;
}

.btn::after {
    top: 50%;
    transform-origin: right;
}

.btn:is(:hover, :focus)::before, .btn:is(:hover, :focus)::after {
    transform: scaleX(1);
}

.btn:is(:hover, :focus)::before {
    transform-origin: right;
}

.btn:is(:hover, :focus)::after {
    transform-origin: left;
}

.btn-primary {
    background-color: var(--raisin-black-1);
    color: var(--white);
}

.btn-primary:is(:hover, :focus) {
    /* color: var(--majorelle-blue); */
}

.btn-secondary {
    background-color: var(--mustard);
    color: var(--raisin-black-1);
    gap: 15px;
}

.btn-secondary::before, .btn-secondary::after {
    background-color: var(--sky-blue-crayola);
}

.btn-secondary:is(:hover, :focus) {
    color: var(--white);
}

.social-list {
    display: flex;
}

.w-100 {
    width: 100%;
}

.section-subtitle {
    color: var(--cool-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-500);
    line-height: 1.2;
}

.section-subtitle::before {
    position: static;
    width: 12px;
    height: 12px;
    background-color: var(--mustard);
    display: inline-block;
    border-radius: 50%;
    margin-inline-end: 10px;
}

.text-center {
    text-align: center;
}

.section-title {
    margin-block: 30px 50px;
    color: var(--light-gray);
}

.grid-list {
    display: grid;
    gap: 30px;
}

.img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
    overflow: hidden;
}

.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.has-bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media (min-width: 575px) {
    .container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }

}

@media (min-width: 768px) {
    :root {
        --fs-1: 5.5rem;
        --fs-2: 3.8rem;
        --fs-7: 1.8rem;
        --section-padding: 120px;
    }

    .container {
        max-width: 720px;
    }

    .btn {
        height: 60px;
    }

    .grid-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }

    .btn {
        height: 70px;
    }

    .grid-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1200px) {

    :root {
        --fs-1: 5.5rem !important;
        --fs-2: 4.5rem !important;

    }

    .container {
        max-width: 1140px;
    }

    .section-title.text-center {
        max-width: 20ch;
        margin-inline: auto;
    }
}