.Contact-Us-Frame {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 140px 90px;
}

.Contact-Us-Frame .Contact-Us-Form {
    width: 54%;
    display: flex;
    min-height: 500px;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 15px;
    padding: 20px;
    border-radius: 9px;
    border: 1px solid var(--raisin-black-1);
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.Contact-Us-Form .Form-Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
}

.Contact-Us-Form .Form-Header .Form-Title {
    font-weight: 700;
    font-size: 3.6rem;
    text-align: center;
    color: var(--ghost-white);
    margin: 0;
}

.Contact-Us-Form .Form-Header .Form-Description {
    max-width: 82%;
    margin: auto;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: center;
    color: var(--cool-gray);
}

.Contact-Us-Form .input-container {
    width: 81%;
    align-self: center;
}

.Contact-Us-Form .input-container .flex-input {
    display: flex;
    flex-direction: row;
    gap: 9px;
}

.Contact-Us-Form .input-container .flex-input .input-group {
    margin: 0;
}

.Contact-Us-Form .input-container .input-group {
    margin: 27px 0;
}

.Contact-Us-Form .input-container .input-group .error-message {
    font-size: 16px;
    color: var(--bittersweet);
    display: flex;
    align-items: center;
}

.Contact-Us-Form .input-container .input-group .error-message span,
.Contact-Us-Form .input-container .input-group .error-message svg {
    margin: 5.4px 5px 0 0;
}

.Contact-Us-Form .input-container .input-group .input-box {
    width: 100%;
    position: relative;
    border-radius: 5.4px;
    border: 1.8px solid var(--cool-gray);
    transition: .5s;
}

.Contact-Us-Form .input-container .input-group .input-box input {
    width: 100%;
    padding: 0 16px;
    height: 40px;
    font-size: 18px;
    border: none;
    color: var(--ghost-white);
    background: none;
    outline: none;
}

.Contact-Us-Form .input-container .input-group .input-box input:focus~label,
.Contact-Us-Form .input-container .input-group .input-box input:valid~label {
    top: -3px;
    color: var(--cool-gray);
    font-weight: 400;
}

.Contact-Us-Form .input-container .input-group .input-box label {
    position: absolute;
    top: 45%;
    left: 9px;
    background-color: #1a1b1b;
    transform: translateY(-50%);
    font-size: 18px;
    pointer-events: none;
    transition: .5s;
    font-family: var(--quick-sand);
}

.Contact-Us-Form .submit-btn {
    width: 63%;
    align-self: center;
    border: none;
    outline: none;
    background: radial-gradient(100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100%);
    box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
    padding: 9px;
    border-radius: 10px;
    color: #fff;
    font-family: var(--Lexend);
    font-size: 21px;
    cursor: pointer;
}

.Contact-Us-Form .submit-btn:hover {
    box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
    transform: translateY(-0.1em);
}

.Contact-Us-Form .Terms-Conditions-Box {
    width: 81%;
    align-self: center;
    text-align: center;
    display: inline-block;
}

.Contact-Us-Form .Terms-Conditions-Box conditions {
    font-family: var(--Lexend);
    font-size: 18px;
    color: #5468FF;
    margin-left: 5px;
    cursor: pointer;
}

.Contact-Us-Form .Terms-Conditions-Box conditions:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .Contact-Us-Frame {
        padding: 140px 30px;
    }

    .Contact-Us-Frame .Contact-Us-Form {
        width: 100%;
    }

    .Contact-Us-Form .input-container {
        width: 100%;
    }

    .Contact-Us-Form .input-container .input-group {
        margin: 15px 0;
    }

    .Contact-Us-Form .input-container .input-group .input-box {
        width: 100%;
    }

    .Contact-Us-Form .submit-btn {
        width: 100%;
    }

    .Contact-Us-Form .Terms-Conditions-Box {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .Contact-Us-Frame {
        padding: 140px 15px;
    }

    .Contact-Us-Frame .Contact-Us-Form {
        width: 100%;
    }
    
    .Contact-Us-Form .flex-input {
        flex-direction: column !important;
    }

    .Contact-Us-Form .input-container {
        width: 100%;
    }

    .Contact-Us-Form .input-container .input-group {
        margin: 15px 0;
    }

    .Contact-Us-Form .input-container .input-group .input-box {
        width: 100%;
    }

    .Contact-Us-Form .submit-btn {
        width: 100%;
    }

    .Contact-Us-Form .Terms-Conditions-Box {
        width: 100%;
    }

}