.our-blogs-frame {
    margin: 120px 50px;
}

.our-blogs-frame .site-content {
    display: grid;
    grid-template-columns: 70% 30%;
}

.post-content > .post-image div{
    margin: 2rem;
    box-shadow: var(--shadow-3);
    box-shadow: inset 0 0 0 30px hsla(var(--color), 0.9);
    border-radius: var(--radius-20);
    border: 1px solid var(--raisin-black-1);
}

.site-content .post-content>.post-image,
.post-title {
    padding: 1rem 2rem;
    position: relative;
}

.site-content .post-content>.post-image .post-info {
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid var(--raisin-black-1);
    backdrop-filter: blur(25px);
    padding: 1rem;
    position: absolute;
    bottom: -3%;
    left: 20%;
    right: 20%;
    display: flex;
    flex-direction: row;
}

.site-content .post-content>.post-image>div {
    overflow: hidden;
}

.site-content .post-content>.post-image .img {
    width: 100%;
    transition: all 1s ease;
}

.site-content .post-content>.post-image .img:hover {
    transform: scale(1.3);
}

.site-content .post-content>.post-image .post-info span {
    margin: 0 .5rem;
}

.post-content .post-title a {
    font-family: var(--Lexend);
    font-weight: 500;
    font-size: 40px;
    color: var(--sunny-yellow);
}

.post-content .post-title p {
    font-size: var(--fs-7);
    color: var(--ghost-white);
    margin: 2rem 4rem;
}


.post-content .post-title .cate {
    font-family: var(--quick-sand);
    font-size: 1rem;
    color: var(--text-gray);
    text-decoration: none;
    display: none;
}

.site-content .post-content .post-title .post-btn {
    border-radius: 0;
    padding: .7rem 1.5rem;
    background: var(--graidient02);
}

.site-content .pagination {
    justify-content: center;
    text-align: --webkit-center;
    color: var(--black);
    margin: 4rem 0;
}

.site-content .pagination a {
    padding: .6rem .9rem;
    border-radius: 2rem;
    margin: 0 .3rem;
    font-family: var(--Lexend);
}

.site-content .pagination .pages {
    background: var(--graidient02);
    color: var(--white);
}